import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    file(relativePath: { eq: "share.jpg" }) {
      relativePath
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const SharePage = ({ data }) => (
  <Layout>
    <SEO title="Share" />
    <div className="content-area">
      <main className="site-main">
        <div className="bg-c-1 padd-xs-b60 padd-lg-b150">
          <div className="container padd-only-sm">
            <div className="row">
              <div className="col-xs-12 marg-xs-t40 marg-lg-t60">
                <section className="c-about">
                  <header className="c-about-header">
                    <h3 className="c-heading">
                      <span>Share</span>
                    </h3>
                  </header>
                  <div className="c-about-img">
                    <Img
                      className="img-responsive"
                      fluid={data.file.childImageSharp.fluid}
                    />
                  </div>

                  <div className="c-about-content-no-footer clearfix">
                    <div className="content-left">
                      <p>
                        If you agree with our philosophy that traveling is
                        sharing the love for the world we live in, we invite you
                        to become a lovethis.world adventurer and contribute to
                        our virtual "home" for travelers. Share your soundbites,
                        stories, recommendations, photos, videos, music, art,
                        grassroot initiatives you support etc. and link it to
                        our globe so others can click on a geographic location
                        and find inspiration.
                      </p>
                      <p>
                        Remember, the focus of lovethis.world is on the{" "}
                        <em>shared</em> experience of travel. We want to feel a
                        part of your journey and of the relationships you create
                        and the world you discover.
                      </p>
                    </div>
                    <div className="content-right">
                      <p>
                        We are firm believers that big change starts local. When
                        travelling we often find inspiration in local projects
                        that others' have created to make the world a little bit
                        brighter. We like to support small scale grass-roots
                        initiatives that we discover through our travels because
                        they address global issues from the perspective of the
                        culture and people these projects target. We love
                        finding ways to give back to the communities that
                        welcome us into their world!
                      </p>
                      <p>
                        Join us by sharing and supporting local causes that
                        inspire you on your travels. Write us about an
                        initiative that you have discovered, with a brief
                        description, contact information, photos or videos and
                        information on how to donate. Or, click our Globe to
                        find inspirational initiatives linked to places where
                        you are planning on traveling or fell in love with
                        recently and want to give back.
                      </p>
                      <div className="info-details">
                        <h5 className="details-title">Do get in touch!</h5>
                        <a href="mailto:ahoy@lovethis.world">
                          ahoy@lovethis.world
                        </a>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* <!-- main --> */}
    </div>
    {/* <!-- primary --> */}
  </Layout>
)

export default SharePage
